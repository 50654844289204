<template>
	<el-scrollbar class="uu-box uu-decorate">
		<el-tabs v-model="activeName" class="uu-tabs" @tab-click="handleClickTabs">
			<el-tab-pane :label="$t('seller.decorate.tabPane3')" name="1"></el-tab-pane>
			<el-tab-pane :label="$t('seller.decorate.tabPane8')" name="6"></el-tab-pane>
		</el-tabs>
		<div class="uu-center">
			<div class="uu-filters" v-if="['1', '2'].includes(activeName)">
				<el-button size="medium" type="primary" @click="hanleDialogOpen" round>
					{{ '+' + $t('seller.actions.newPage') }}
				</el-button>
			</div>
			<el-dialog :title="$t('seller.dialogTitle.newPage')" :visible.sync="dialogVisible" width="450px"
				:before-close="handleDialogClose">
				<el-form ref="postForm" :model="postForm" :rules="postRules">
					<el-form-item :label="$t('seller.formItem.pageName')" prop="name">
						<el-input v-model="postForm.name" :placeholder="$t('seller.placeholder.pageName')"
							maxlength="25" show-word-limit></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="handleDialogConfirm">{{ $t('seller.actions.confirm') }}</el-button>
					<el-button @click="handleDialogClose">{{ $t('seller.actions.cancel') }}</el-button>
				</div>
			</el-dialog>
			<el-table :data="decorateList" size="small" style="width: 100%;" header-row-class-name="uu-thead">
				<el-table-column
					:label="activeName === '1' ? $t('seller.tableHead.navName') : $t('seller.tableHead.pageName')"
					prop="name">
					<template slot-scope="scope">
						<el-input v-if="renameId === scope.row.wap_store_page_id" v-model="scope.row.name" size="small"
							maxlength="25" show-word-limit @change="handleEditName(scope)"></el-input>
						<span v-else>{{ scope.row.name }}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.updateTime')">
					<template slot-scope="scope">
						{{ scope.row.update_time | parseTime() }}
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.status')">
					<template slot-scope="scope">
						<el-tag :type="scope.row.status | typeFilter(['gray', 'success'])" size="mini" class="item-tag">
							{{ scope.row.status | typeFilter($t('seller.options.publishStatus')) }}
						</el-tag>
						<el-tag v-if="scope.row.is_online == 1" type="warning" size="mini" class="item-tag">
							{{ $t('seller.tag.onlineHome') }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.handle')" min-width="200px">
					<template slot-scope="scope">
						<template v-if="activeName === '1'">
							<router-link :to="{ name: 'wpPageEditor', params: { id: scope.row.wap_store_page_id } }"
								class="item-link" target="_blank">{{ $t('seller.actions.decoratePage') }}</router-link>
							<el-button type="text" v-if="scope.row.is_online !== 1" :disabled="scope.row.status !== 1"
								class="item-link" @click="handleDefault(scope)">{{ $t('seller.actions.setHome') }}
							</el-button>
							<el-button type="text" :disabled="scope.row.is_online === 1" class="item-link"
								@click="handleDelete(scope)">{{ $t('seller.actions.deletePage') }}</el-button>
							<!-- <el-button type="text" class="item-link">{{$t('seller.actions.copyLink')}}</el-button> -->
							<el-button type="text" class="item-link" @click="renameId = scope.row.wap_store_page_id">
								{{ $t('seller.actions.rename') }}</el-button>
							<!-- <el-button type="text" class="item-link">{{$t('seller.actions.copyShortChain')}}</el-button> -->
						</template>
						<template v-if="activeName === '6'">
							<router-link :to="{ name: 'pageEditor', params: { id: scope.row.wap_store_page_id } }"
								class="item-link" target="_blank">{{ $t('seller.actions.decoratePage') }}</router-link>
						</template>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="uu-footer" v-if="listTotal > 0">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page.sync="pagecurr" :page-size="pagesize" layout="total, prev, pager, next"
				:prev-text="$t('seller.pagination.prevText')" :next-text="$t('seller.pagination.nextText')"
				:total="listTotal">
			</el-pagination>
		</div>
	</el-scrollbar>
</template>

<script>
import {
	getMobileDecorate,
	createMobileDecorate,
	updateMobileDecorate,
	onlineMobileDecorate,
	deleteMobileDecorate
} from '@/api/seller/shop'
export default {
	data() {
		return {
			activeName: '1',
			pagesize: 10,
			pagecurr: 1,
			listTotal: 0,
			decorateList: [],
			dialogVisible: false,
			postForm: {
				name: ''
			},
			postRules: {
				name: [{
					required: true,
					message: this.$t('seller.validator.pageName'),
					trigger: 'blur'
				}]
			},
			renameId: 0
		}
	},
	created() {
		this.getDecorateList()
	},
	methods: {
		initDecorate() {
			const option = { 1: '默认', 6: '店铺印象' }
			const params = {
				name: option[this.activeName],
				type: this.activeName,
				status: 1
			}
			createMobileDecorate(params).then(() => {
				this.getDecorateList();
			})
		},
		getDecorateList() {
			let loading = this.$loading();
			let param = {
				type: this.activeName,
				page_index: (this.pagecurr - 1) * this.pagesize,
				page_size: this.pagesize
			}
			getMobileDecorate(param).then(response => {
				loading.close();
				this.decorateList = response.data.list
				this.listTotal = parseInt(response.data.total)
				if (!response.data.list.length > 0) {
					this.initDecorate()
				}
			}).catch(() => {
				loading.close()
			})
		},
		handleClickTabs(tab, event) {
			this.pagecurr = 1
			this.decorateList = []
			this.listTotal = 0
			this.getDecorateList()
		},
		handleSizeChange(val) {
			this.pagesize = val
			this.getDecorateList()
		},
		handleCurrentChange(val) {
			this.pagecurr = val
			this.getDecorateList()
		},
		hanleDialogOpen() {
			this.dialogVisible = true
		},
		handleDialogClose() {
			this.dialogVisible = false
		},
		handleDialogConfirm() {
			this.$refs.postForm.validate(valid => {
				if (valid) {
					let param = {
						...this.postForm,
						type: this.activeName
					}
					createMobileDecorate(param).then(() => {
						this.$message.success(this.$t('seller.successMsg.add'))
						this.getDecorateList();
						this.dialogVisible = false;
					})
				} else {
					return false
				}
			})
		},
		handleEditName({ row }) {
			if (!row.name) {
				return false;
			}
			updateMobileDecorate(row).then(() => {
				this.renameId = 0
				this.getDecorateList();
			})
		},
		handleDefault({ row }) {
			this.$confirm(this.$t('seller.confirm.defaultPageText'), '', {
				confirmButtonText: this.$t('seller.actions.confirm'),
				cancelButtonText: this.$t('seller.actions.cancel'),
				type: 'warning'
			}).then(() => {
				let param = {
					wap_store_page_id: row.wap_store_page_id,
					column: 'is_online',
					value: 1
				}
				onlineMobileDecorate(param).then(() => {
					this.getDecorateList();
					this.$message.success(this.$t('seller.successMsg.set'))
				})
			}).catch(() => {
				this.$message(this.$t('seller.cancelMsg.set'));
			})
		},
		handleDelete({ row }) {
			this.$confirm(this.$t('seller.confirm.deleteText1') + row.name + this.$t('seller.confirm.deleteText2'),
				'', {
				confirmButtonText: this.$t('seller.actions.confirm'),
				cancelButtonText: this.$t('seller.actions.cancel'),
				type: 'warning'
			}).then(() => {
				deleteMobileDecorate({
					wap_store_page_id: row.wap_store_page_id
				}).then(() => {
					this.getDecorateList();
					this.$message.success(this.$t('seller.successMsg.delete'))
				})
			}).catch(() => {
				this.$message(this.$t('seller.cancelMsg.delete'));
			})
		}
	}
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs {
	&.uu-tabs {
		.el-tabs__header {
			margin-bottom: 0;

			.el-tabs__nav-wrap {
				.el-tabs__nav {
					margin-left: 20px;
					.el-tabs__item {
						height: 54px;
						line-height: 54px;
					}
				}

				&:after {
					height: 1px;
				}
			}
		}
	}
}

.uu-decorate {
	.uu-center {
		padding: 24px;
	}

	.uu-filters {
		margin-bottom: 18px;
	}

	.item-tag {
		margin-right: 6px;

		&:last-child {
			margin-right: 0;
		}
	}

	.item-link {
		display: inline-block;
		margin: 0 12px;
		font-size: 12px;
		color: $--seller-primary;

		&.is-disabled {
			color: #999999;
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.uu-footer {
		text-align: center;
		padding: 15px 0;
	}

}

::v-deep .el-table {
	.uu-thead {
		background-color: #F7F8FA;

		th {
			background-color: #F7F8FA;
		}
	}
}
</style>
